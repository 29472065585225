import React from "react";

import './Footer.css';

const Footer = () => (
    <div className='footer'>
        Made with ❤️ by Louis Jpr
    </div>
);

export default Footer;
